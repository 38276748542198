<template>
  <div>
    <textarea id="editor-tinymce"></textarea>
    <ModalImagen ref="modalImagen" @close-modal="closeModal" :images="images" />
  </div>
</template>

<script>
import ModalImagen from "../modals/ModalImagen.vue";
export default {
  name: 'TinyMCEEditorV2',
  components: {
    ModalImagen,
  },
  props: {
    value: String,
  },
  data(){
    return {
      varitems: [],
      images: [],
    }
  },
  mounted() {
    this.initModuleEditor();
    this.getImages();
  },
  methods:{
    initModuleEditor(){
        const script = document.createElement('script');
        script.src = `${this.$apiAdress}/dist/tinymce/js/tinymce/tinymce.min.js`,  // Ajusta la ruta según la estructura de tu proyecto
        script.onload = this.initEditor;
        document.head.appendChild(script);
    },
    initEditor(){
      tinymce.init({
        selector: `textarea#editor-tinymce`,
        toolbar_mode: "sliding",
        height: "600",
        plugins: "anchor autolink charmap codesample emoticons link lists media searchreplace table visualblocks wordcount code",
        toolbar: "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link imageCustom media table mergetags | align lineheight | numlist bullist indent outdent | code emoticons vars ",
        language_url: `${this.$apiAdress}/dist/tinymce/langs/es/es_MX.js`,
        language: "es_MX",
        image_description: false,
        automatic_uploads: true,
        file_picker_types: "image",
        file_picker_callback: this.file_picker_callback_handler,
        images_upload_handler: this.handleImageUpload,
        setup: (editor) => {
          editor.setContent(this.value);
          this.buttonVarsEditor(editor);
          this.buttonImageCustom(editor);
        },
      });
    },
    buttonImageCustom(editor){
      //button custom image
      let self = this;
      editor.ui.registry.addButton("imageCustom", {
        icon: "gallery",
        tooltip: "Insertar imagen",
        onAction: () => {
           self.$refs.modalImagen.abriModalEditor(this.images);
        },
      });
    },
    buttonVarsEditor(editor){
        var menuItems = this.varitems.map(function (iterator) {
        return {
          type: "menuitem",
          text: iterator.name,
          onAction: function () {
            editor.insertContent(`&nbsp;<span>{{${iterator.name}}}</span>`);
          },
        };
      });
      /* example, adding a toolbar menu button */
      editor.ui.registry.addMenuButton("vars", {
        text: "Variables",
        fetch: function (callback) {
          callback(menuItems);
        },
      });
    },
    getContent() {
      const editor = tinymce.get("editor-tinymce");
      if (editor) {
        return editor.getContent();
      }
      return '';
    },
    setContent(content) {
      const editor = tinymce.get("editor-tinymce");
      if (editor) {
        editor.setContent(content);
      }
    },
    setVarsItemsGrupos(content) {
      this.varitems = content;
      const editor = tinymce.get("editor-tinymce");
      if (editor) {
        let datahtml = editor.getContent();
        editor.destroy();
        this.initEditor();
        editor.setContent(datahtml);
      }
    },
    getImages() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http.get(this.$httpUrlMail+"/mail/images/boletines", {
          withCredentials: false,
        })
        .then((response) => {
          this.images = response.data;
        })
        .catch((error) => {
          console.error(error);
        }).finally(() => {this.$store.dispatch("auth/loading_http", "hidden")});
    },
    closeModal(key,message = '',flag = false) {
      switch (key) {
        case "Close_Modal_Image_Success":
          if (message != '') {
             tinymce.get("editor-tinymce").insertContent(message);
          }
          if (flag) {
            this.getImages();
          }
          break;
      }
    },
    file_picker_callback_handler (cb, value, meta) {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.onchange = function () {
          var file = this.files[0];
          var reader = new FileReader();
          reader.onload = function () {
            var id = "blobid" + new Date().getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(",")[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
    },
    handleImageUpload(blobInfo, success, failure) {
      const data = new FormData();
      data.append("file", blobInfo.blob(), blobInfo.filename());
      this.$http
        .post(this.$httpUrlMail + "/mail/boletines/upload", data, {
          withCredentials: false,
        })
        .then((response) => {
          // Lógica para manejar la respuesta de carga de imágenes
          const imageUrl = response.data.location;
          this.getImages();
          success(imageUrl);
        })
        .catch((error) => {
          // Maneja los errores de carga de imágenes
          failure("Error al cargar la imagen");
          console.error(error);
        });
    },
  },
  watch: {
    value(newValue) {
      const editor = tinymce.get("editor-tinymce");
      if (editor) {
        // Evita cambiar el contenido si ya está en la posición correcta
        if (editor.getContent() !== newValue) {
          editor.setContent(newValue);
        }
      }
    },
  },
  beforeDestroy() {
    tinymce.get("editor-tinymce").destroy();
  },
};
</script>
