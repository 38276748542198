<template>
  <div>
    <CModal
      :show.sync="openModalImage"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
       
    >
      <template>
        <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="alertWarning">
          {{messageWarning}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertWarning = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ul class="nav nav-tabs justify-content-center">
          <li class="nav-item">
            <a :class="'nav-link '+(isActive == 1 ? 'active' : '')" @click="funcVisibleA">Cargar Url</a>
          </li>
          <li class="nav-item">
            <a :class="'nav-link '+(isActive == 2 ? 'active' : '')" @click="funcVisibleB">Subir Imagen</a>
          </li>
          <li class="nav-item">
            <a :class="'nav-link '+(isActive == 3 ? 'active' : '')" @click="funcVisibleC">Seleccionar Imagen</a>
          </li>
        </ul>    
        <CCollapse :show="visibleA">
          <CCard class="mt-3">
            <CCardBody>
              <CRow>
                <CCol col="12">
                  <CInput
                    label="Url de la imagen"
                    type="text"
                    v-model="urlImageManually"
                  />
                </CCol>
              </CRow>
              <!--<CRow>
                <CCol col="6">
                  <CInput
                    label="Ancho"
                    type="text"
                    v-model="anchoImagenUrl"
                    @input="updateWidth"
                  />
                </CCol>
                <CCol col="6">
                  <CInput
                    label="Alto"
                    type="text"
                    v-model="altoImagenUrl"
                    @input="updateHeight"
                  />
                </CCol>
              </CRow>-->
              <CRow>
                <CCol col="12">
                  <CButton
                    color="primary"
                    @click="addUrlManually"
                    class="mt-3"
                  >
                    Cargar Imagen
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCollapse>
        <CCollapse :show="visibleB">
          <CCard class="mt-3">
            <CCardBody>
              <CRow>
                <CCol col="12">
                  <CInputFile
                    id="file-input"
                    label="Subir Imagen"
                    accept="image/*"
                    :key="fileInputKey"
                    @change="uploadImageHandler"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <CButton
                    color="primary"
                    @click="uploadImage"
                    class="mt-3"
                  >
                    Cargar Imagen
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCollapse>
        <CCollapse :show="visibleC">
          <CCard class="mt-3">
            <CCardBody>
               <div>
                <CRow>
                  <CCol col="3" v-for="(field,index) in images" :key="index">
                    <div class="image-container">
                      <img :src="field.url" width="80px" height="80px" />
                      <div class="buttons-container">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="imagenSeleccionada" @click="checkRadioImage = index">
                        <br>
                        <div v-if="checkRadioImage == index">
                          <span class="badge badge-pill badge-danger" style="cursor:pointer;" @click="deleteImage(field.id,index)">Eliminar</span>
                          <span class="badge badge-pill badge-info" style="cursor:pointer;" @click="addImageView">Agregar</span>
                        </div>
                      </div>
                    </div>
                    </div>
                  </CCol>
                </CRow>
                
               </div>
            </CCardBody>
          </CCard>
        </CCollapse>
        <input type="hidden" v-model="modalLog">
      </template>

      <template #header>
        <h6 class="modal-title">Agregar Imagen</h6>
        <CButtonClose
          @click="closeModal('Close_Modal_Image_Success')"
          class="text-white"
        />
      </template>
      <template #footer>
        <CButton
          @click="closeModal('Close_Modal_Image_Success')"
          color="danger"
          >Cerrar</CButton
        >
        <CButton
          @click="closeModal('Close_Modal_Image_Success')"
          color="success"
          style="display: none"
          >Accept</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "PreviewHtml",
  components: {},
  data() {
    return {
      openModalImage: false,
      visibleA: true,
      visibleB: false,
      visibleC: false,
      isActive: 1,
      urlImageManually: "",
      alertWarning: false,
      messageWarning: "",
      fileInputKey: 0,
      imageFiles: null,
      checkRadioImage: null,
      anchoImagenUrl: 0,  
      altoImagenUrl: 0,
      anchoOriginal: 0,
      altoOriginal: 0,
      aspectRatio: 0,
    };
  },
  props: {
    openModal: Boolean,
    preview_Html: String,
    images: Array,
  },
  computed: {
    modalLog(){
      this.openModalImage = this.openModal;
      return this.openModal;
    }
  },
   watch: {
    altoImagenUrl(newValue) {
      if (newValue <= 0) {
        this.altoImagenUrl = 1;
      }
    },
    anchoOriginal(newValue) {
      if (newValue <= 0) {
        this.anchoOriginal = 1;
      }
    },
  },
  methods: {
    funcVisibleA() {
      this.visibleB = false;
      this.visibleC = false;
      this.visibleA = !this.visibleA;
      this.isActive = 1;
    },
    funcVisibleB() {
      this.visibleA = false;
      this.visibleC = false;
      this.visibleB = !this.visibleB;
      this.isActive = 2;
    },
    funcVisibleC() {
      this.visibleA = false;
      this.visibleB = false;
      this.visibleC = !this.visibleC;
      this.isActive = 3;
    },
    closeModal(type) {
      this.openModalImage = false;
      this.$emit("close-modal", type);
    },
    open_modal_image_custom_tinymce() {
      this.openModalImage = true;
    },
    addUrlManually() {
      if (this.urlImageManually == "") {
        this.alertWarning = true;
        this.messageWarning = "Debe ingresar una url";
        return;
      }
      if (!this.validateURL(this.urlImageManually)) {
        this.alertWarning = true;
        this.messageWarning = "Debe ingresar una url valida o que sea una imagen";
        return;
      }
      let url = this.urlImageManually;
      let content = `<img src="${url}" alt="" width="${this.anchoImagenUrl}" height="${this.altoImagenUrl}">`;
      this.urlImageManually = "";
      this.openModalImage = false;
      this.$emit("close-modal", 'Close_Modal_Image_Success',content);
    },
    validateURL(url) {
        const imageExtensions = ["jpg", "jpeg", "png", "gif"];
        const extension = url.split(".").pop().toLowerCase();
        if (imageExtensions.includes(extension)) {
          const regex = /^(ftp|http|https):\/\/[^ "]+$/;
          if (regex.test(url)) {
            return true;
          }
        }
        return false;
        return imageExtensions.includes(extension);
    },
    uploadImage(event) {
      let file = this.imageFiles;
      
      if (file == undefined) {
        return;
      }
      if (!file.type.includes("image")) {
        this.alertWarning = true;
        this.messageWarning = "Debe seleccionar una imagen";
        return;
      }
      this.handleImageUpload(file);
    },
    handleImageUpload(blobInfo) {
      this.$store.dispatch("auth/loading_http", "visible");
      const data = new FormData();
      data.append("file", blobInfo);
      this.$http.post(this.$httpUrlMail + "/mail/boletines/upload", data, {
          withCredentials: false,
        },{
            headers: { "content-type": "multipart/form-data" },
          })
        .then((response) => {
            const imageUrl = response.data.location;
            let content = `<img src="${imageUrl}" alt="">`;
            this.fileInputKey += 1;
            this.imageFiles = null;
            this.openModalImage = false;
            this.$emit("close-modal", 'Close_Modal_Image_Success',content,true);
        })
        .catch((error) => {
          // Maneja los errores de carga de imágenes
          this.alertWarning = true;
          this.messageWarning = "Error al cargar la imagen";
        }).finally(() => {this.$store.dispatch("auth/loading_http", "hidden")});
    },
    uploadImageHandler(files,event) {
      let file = files[0];
      if (file == undefined) {
        return;
      }
      if (!file.type.includes("image")) {
        this.alertWarning = true;
        this.messageWarning = "Debe seleccionar una imagen";
        return;
      }
      this.imageFiles = file;
    },
    deleteImage(id,index) {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .delete(this.$httpUrlMail + "/mail/images/boletines/delete/" + id,{withCredentials: false})
        .then((response) => {
          this.images.splice(index, 1);
        })
        .catch((error) => {
          console.error(error);
        }).finally(() => {this.$store.dispatch("auth/loading_http", "hidden")});
    },
    addImageView() {
      if (this.checkRadioImage == null) {
        this.alertWarning = true;
        this.messageWarning = "Debe seleccionar una imagen";
        return;
      }
      let url = this.images[this.checkRadioImage].url;
      this.checkRadioImage = null;
      let content = `<img src="${url}" alt="" >`;
      this.openModalImage = false;
      this.$emit("close-modal", 'Close_Modal_Image_Success',content);
    },
    obtenerTamanioImagen() {
      try {
        let img = new Image();
        img.src = this.urlImageManually;
        img.onload = () => {
          this.anchoOriginal = img.width;
          this.altoOriginal = img.height;
          this.anchoImagenUrl = img.width;
          this.altoImagenUrl = img.height;
        };
      } catch (error) {
        
      }
    },
    updateHeight() {
      this.altoImagenUrl = Math.round(this.anchoImagenUrl / this.aspectRatio);
    },
    updateWidth() {
      this.anchoImagenUrl = Math.round(this.altoImagenUrl * this.aspectRatio);
    },
    setOriginalSize(originalWidth, originalHeight) {
      this.originalWidth = originalWidth;
      this.originalHeight = originalHeight;
      this.aspectRatio = originalWidth / originalHeight;
      this.width = originalWidth;
      this.height = originalHeight;
    },
    abriModalEditor(content) {
      this.openModalImage = true;
      this.images = content;
      this.checkRadioImage = null;
    },
  },
  mounted() {
    this.checkRadioImage = null;
    this.$root.$on("open-modal-image-custom-editor-tinymce", (param1) => {
      this.openModalImage = param1;
    });
  },
};
</script>
<style>
.image-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.image-item {
  margin: 10px;
}

.image-container {
  position: relative;
}

.thumbnail-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.delete-overlay {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  height: 100%;
}

.delete-icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.image-container:hover .delete-overlay {
  opacity: 1;
}
</style>
